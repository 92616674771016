import { generateImageUrl } from '@capturi/request'
import { Role, User, useOrganization, useUsers } from '@capturi/stores'
import {
  List,
  ListItem,
  ListItemElementLeft,
  ListItemText,
} from '@capturi/ui-components'
import {
  Avatar,
  AvatarGroup,
  Popover,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Tag,
  Text,
} from '@chakra-ui/react'
import { Trans, select } from '@lingui/macro'
import { LibraryFolderPermissions } from 'features/library/types'
import React, { useMemo } from 'react'

type Props = {
  permissions?: LibraryFolderPermissions | null
}

const ViewFolderPermissions: React.FC<Props> = ({ permissions }) => {
  if (permissions == null) return null
  const { users, roles } = permissions ?? {}
  if (users?.length) {
    return <UsersView userIds={users} />
  }
  if (roles?.length) {
    return <RolesView roles={roles} />
  }
  return null
}

const UsersView: React.FC<{ userIds: string[] }> = ({ userIds }) => {
  const { getUserByUid } = useUsers()
  const { conversationLanguage } = useOrganization()
  const compare = Intl.Collator(conversationLanguage).compare
  const users = useMemo(() => {
    return userIds.map(getUserByUid).sort((a, b) => compare(a.name, b.name))
  }, [userIds, getUserByUid, compare])
  return (
    <Popover trigger="hover" placement="auto-end">
      <UserListPopover users={users}>
        <AvatarGroup size="sm" max={3}>
          {users.map((user) => (
            <Avatar
              key={user.uid}
              name={user.name}
              src={generateImageUrl(user.profileImage?.key, { size: 32 })}
            />
          ))}
        </AvatarGroup>
      </UserListPopover>
    </Popover>
  )
}

const localizeRole = (role: Role): string =>
  select(role, {
    administrator: 'Administrator',
    teamlead: 'Team lead',
    user: 'User',
    other: 'unknown',
  })

const RolesView: React.FC<{ roles: Role[] }> = ({ roles }) => {
  const { users: allUsers } = useUsers()
  const users = useMemo(() => {
    const rolesSet = new Set(roles)
    return allUsers.filter((x) => x.role && rolesSet.has(x.role))
  }, [allUsers, roles])
  return (
    <UserListPopover users={users}>
      <Stack direction="row" spacing={2} wrap="wrap">
        {roles.map((role) => (
          <Tag key={role} mb="2px">
            {localizeRole(role)}
          </Tag>
        ))}
      </Stack>
    </UserListPopover>
  )
}

const UserListPopover: React.FC<{
  users: User[]
  children?: React.ReactNode
}> = ({ users, children }) => {
  return (
    <Popover trigger="hover" placement="auto-end">
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent py={2}>
        <PopoverCloseButton />
        <Text fontWeight="medium" px={4}>
          <Trans>Users with access</Trans>{' '}
          <Text as="span" color="textMuted" fontWeight="normal">
            ({users.length})
          </Text>
        </Text>
        <List dense maxH="50vh" overflow="auto">
          {users.map((user) => (
            <ListItem key={user.uid}>
              <ListItemElementLeft>
                <Avatar
                  size="xs"
                  name={user.name}
                  src={generateImageUrl(user.profileImage?.key, { size: 32 })}
                />
              </ListItemElementLeft>
              <ListItemText>{user.name}</ListItemText>
            </ListItem>
          ))}
        </List>
      </PopoverContent>
    </Popover>
  )
}

export default ViewFolderPermissions
